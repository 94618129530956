import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ccec5bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "back-link",
      "aria-label": "Return home"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('@/assets/projects/fa/arrow-left-solid.svg'),
          width: "30",
          height: "30",
          alt: "arrow-left"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }),
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
  ]))
}