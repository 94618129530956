import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_P5js = _resolveComponent("P5js")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Title, { title: _ctx.title }, null, 8, ["title"]),
    _createVNode(_component_P5js, { src: _ctx.src }, null, 8, ["src"])
  ], 64))
}